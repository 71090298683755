import React from "react";
import { sectionVars } from "../animations/PageTransitions";
import { Line, StyledSkills } from "./styles/StyledComps";
//animation controller
import SectionAnims from "../util/SectionAnims";
import JsIcon, {
  SassIcon,
  ReactIcon,
  Figma,
  Framer,
  Redux,
  GitLogo,
  NodeLogo,
  TsIcon,
  NextIcon,
  Antd,
} from "../images/logos";

const SkillSection = () => {
  const [skillRef, animToUse] = SectionAnims();
  const [skills, anim] = SectionAnims("skills");
  return (
    <StyledSkills
      ref={skillRef}
      animate={animToUse}
      variants={sectionVars}
      id="about"
    >
      <h2 className="title">About me</h2>
      <h2 className="aboutme">
        My name is
        <span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/davit-darsavelidze-b36505210/"
          >
            Davit Darsavelidze
          </a>
        </span>
        . I'm a frontend developer with 5 years of experience and counting. I
        enjoy tackling and understanding the challenging parts of the web. A
        typical web developer's goal is to create websites, mine is to make them{" "}
        <span>robust</span>,<span> performant</span>, <span>pixel-perfect</span>{" "}
        and easily <span>scalable</span>.
      </h2>
      <div className="skillset">
        <h3 className="desc">
          Skillset <span></span>
        </h3>
        <div className="skillsetIcons" ref={skills}>
          <div className="logobox">
            <JsIcon anim={anim} />
            <Line color="#F7DF1E" />
            <p>
              6+ years of professional experience and deep understanding of
              JavaScript. Always up to date with all the ESNext concepts.
            </p>
          </div>
          <div className="logobox">
            <ReactIcon anim={anim} />
            <Line color="#53C1DE" />
            <p>
              5+ years of professional experience with creating maintainable,
              scalable and well tested web apps with React.
            </p>
          </div>
          <div className="logobox">
            <TsIcon anim={anim} />
            <Line color="#007ACB" />
            <p>
              3+ years of professional experience of working with large-scale TS
              codebases.
            </p>
          </div>

          <div className="logobox">
            <NextIcon anim={anim} />
            <Line color="#000" />
            <p>
              3+ years of professional experience of creating SEO friendly
              Next.js apps.
            </p>
          </div>

          <div className="logobox">
            <SassIcon anim={anim} />
            <Line color="#CF649A" />
            <p>
              5+ years of Sass experience. Pixel perfect implementation of a
              given design.
            </p>
          </div>
        </div>
      </div>
      <div className="otherskills">
        <div className="flexgroup">
          <h4>Other Skils</h4>
          <span></span>
        </div>
        <div className="skilllist">
          <div className="skillitem" style={{ marginLeft: "1rem" }}>
            <div className="skillicon">
              <Antd />
            </div>
            <div className="skillname">Ant Design</div>
          </div>

          <div className="skillitem">
            <div className="skillicon">
              <Redux />
            </div>
            <div className="skillname">Redux</div>
          </div>
          <div className="skillitem">
            <div className="skillicon">
              <Framer />
            </div>
            <div className="skillname framer">Framer Motion</div>
          </div>
          <div className="skillitem styled">
            <div className="skillicon">💅</div>
            <div className="skillname">Styled Components</div>
          </div>

          <div className="skillitem">
            <div className="skillicon">
              <Figma />
            </div>
            <div className="skillname">Figma</div>
          </div>

          <div className="skillitem">
            <div className="skillicon">
              <GitLogo />
            </div>
            <div className="skillname">Git</div>
          </div>
          <div className="skillitem">
            <div className="skillicon">
              <NodeLogo />
            </div>
            <div className="skillname">Node.js</div>
          </div>
        </div>
      </div>
      <div className="languages">
        <div className="flexgroup">
          <h4>Languages </h4>
          <span></span>
        </div>
        <div className="flexright">
          <p>English - native proficiency, Georgian - native </p>
        </div>
      </div>
      <div className="futuregoals">
        <div className="flexgroup">
          <h4>Future Goals</h4>
          <span></span>
        </div>
        <div className="flexright">
          <p> Go, Micro Frontends, Web3, Three.js and WebXR API</p>
        </div>
      </div>
    </StyledSkills>
  );
};

export default SkillSection;
